import Image from 'next/image';
import Link from 'next/link';

import SearchBox from 'components/Common/header/CustomSearchBox';
import { mediaStoreBaseURL } from 'config/common';

export default function Banner({
  eventId,
  translate,
  changeDirection,
  homeScreen
}) {
  const banner = {
    bannerImageUrl: translate.t('imageUrl'),
    bannerHeading: translate.t('heading'),
    bannerDescription: translate.t('description'),
    getInTouch: translate.t('getInTouch')
  };

  return (
    <>
      <div className='relative top-0 w-full h-screen/2 md:h-screen/1.3 text-white flex items-center z-10 cursor-pointer'>
        <div className='absolute top-0 w-full bottom-3 bg-black'>
          <Image
            src={`${mediaStoreBaseURL}/${banner.bannerImageUrl}`}
            layout='fill'
            objectFit='cover'
            alt='landing image'
            className='opacity-50'
          />
        </div>
        <div className='text-center w-full flex flex-col'>
          <h1
            className='relative mt-12
          text-white text-3xl
           mx-1 md:mx-auto
           md:text-5xl
           font-bold z-10
          transform translate-y-0'
          >
            {banner.bannerHeading}
          </h1>
          {!homeScreen && (
            <Link href={`/lead-capture-form/${eventId}`}>
              <div>
                <button
                  className={`relative bg-white hover:bg-platinum-100 md:mt-7 mt-5 text-black font-bold p-3 md:p-4 shadow rounded-xl w-30 md:w-50`}
                >
                  {banner.getInTouch}
                </button>
              </div>
            </Link>
          )}
          {homeScreen && (
            <div className=' hidden md:block relative mx-auto'>
              <SearchBox />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
