import Head from 'next/head';
import { useState } from 'react';

import ProgressBar from '@/components/atomic/atoms/common/ProgressBar';
import Banner from 'components/Common/Banner';
import DesktopHomeBanner from 'components/Common/desktop-home-banner';
import Footer from 'components/Common/Footer';
import MobileHomeBanner from 'components/Common/mobile-home-banner';
import { useUIContext } from 'components/context/uiContext/ManagedUiContext';

export default function Layout({ paramData, children }) {
  const [direction, setDirection] = useState('ltr');

  const changeDirection = () => {
    setDirection((prevState) => {
      if (prevState === 'rtl') {
        setDirection('ltr');
      } else {
        setDirection('rtl');
      }
    });
  };
  const surfaceBanners = {
    mobile: MobileHomeBanner,
    web: DesktopHomeBanner
  };
  const { surface } = useUIContext();
  const FinalBanner = paramData.homeScreen
    ? surfaceBanners[surface]
    : () => (
        <Banner
          eventId={paramData.eventCategory}
          changeDirection={changeDirection}
          translate={paramData.bannerTranslate}
          homeScreen={paramData.homeScreen}
        />
      );

  return (
    <>
      <Head>
        <title>{paramData.bannerTranslate.t('meta-title')}</title>
        <meta
          name='description'
          content={paramData.bannerTranslate.t('meta-description')}
        />
        <meta
          property='og:title'
          content={paramData.bannerTranslate.t('meta-title')}
        />
        <meta
          property='og:description'
          content={paramData.bannerTranslate.t('meta-description')}
        />
      </Head>
      <div dir={direction}>
        <ProgressBar />
        {!paramData.hideBanner && <FinalBanner />}

        <main className='w-full overflow-hidden'>{children}</main>
        <Footer />
        {paramData.homeScreen && <div className='md:hidden h-14'></div>}
      </div>
    </>
  );
}
