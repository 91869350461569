import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faPinterestP,
  faTiktok,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';

import { staticMediaStoreBaseURL } from 'config/common';

// TODO: twitter, pinterest account are not active, Need to ask, if we can remove
const socialMediaIconList = [
  {
    url: 'https://www.linkedin.com/company/hafla-official',
    faIcon: faLinkedinIn,
    isVisible: true,
    color: '#DF7447'
  },
  {
    url: 'https://www.youtube.com/channel/UCaUo41TfdsE8Lr_OCb-modg',
    faIcon: faYoutube,
    isVisible: true,
    color: '#E56A66'
  },
  {
    url: 'https://www.facebook.com/hafla.Official',
    faIcon: faFacebookF,
    isVisible: true,
    color: '#E2627E'
  },
  {
    url: 'https://www.instagram.com/hafla.official',
    faIcon: faInstagram,
    isVisible: true,
    color: '#DB589A'
  },
  {
    url: 'http://www.tiktok.com/@haflaofficial',
    faIcon: faTiktok,
    isVisible: true,
    color: '#DD5489'
  },
  {
    url: 'https://twitter.com/haflaOfficial',
    faIcon: faTwitter,
    isVisible: false
  },
  {
    url: 'https://in.pinterest.com/HaflaOfficial',
    faIcon: faPinterestP,
    isVisible: false
  }
];

const exploreSectionData = [
  { href: '/', textKey: 'home' },
  { href: '/events/corporate-events', textKey: 'corporate' },
  { href: '/events/wedding-events', textKey: 'weddings' },
  { href: '/events/celebrations', textKey: 'others' }
];

const policyData = [
  { href: '/terms-and-conditions', textKey: 'terms' },
  { href: '/privacy-policy', textKey: 'privacyPolicy' },
  { href: '/acceptable-use-policy', textKey: 'usePolicy' }
];

const companySectionData = [
  { href: '/about', textKey: 'about', additionalClass: ' font-thin text-sm' },
  {
    href: 'https://www.hafla.com/blog/',
    textKey: 'blog',
    additionalClass: ' text-xs'
  },
  { href: '/contact-us', textKey: 'contact', additionalClass: ' text-xs' }
];

export default function Footer() {
  const { t: translate } = useTranslation('footer');

  const SocialMediaIcon = ({
    url,
    faIcon,
    iconClass = '',
    className,
    anchorStyle,
    iconStyle
  }) => (
    <a
      key={url}
      target='_blank'
      href={url}
      className={className}
      style={anchorStyle}
      rel='noreferrer'
    >
      <li className='flex items-center justify-center'>
        <FontAwesomeIcon
          icon={faIcon}
          className={iconClass}
          style={iconStyle}
        />
      </li>
    </a>
  );

  const ExploreSection = ({ textKey, href }) => (
    <li className='mt-1.5 md:mt-3 text-xs md:text-base font-extralight max-w-max leading-4 text-white hover:text-white active:text-white'>
      <Link href={href}>{translate(textKey)}</Link>
    </li>
  );

  const CompanySection = ({ textKey, href, additionalClass }) => {
    const className = `mt-1.5 md:mt-3 md:text-base font-extralight max-w-max leading-4 text-white hover:text-white active:text-white ${additionalClass}`;
    return (
      <li className={className}>
        <a href={href}>{translate(textKey)}</a>
      </li>
    );
  };

  return (
    <footer className=''>
      <div className='bg-nero p-5 md:px-20 md:py-15 footer-padding-bottom'>
        <div className='flex flex-wrap'>
          <div className='w-full md:w-1/4'>
            <Link href='/'>
              <a
                aria-label='Go home'
                title='Hafla'
                className='items-center'
              >
                <Image
                  width={104}
                  height={34}
                  layout='fixed'
                  src={`${staticMediaStoreBaseURL}/logos/hafla_new.svg`}
                  alt='Hafla'
                  className='inline-block max-w-17.5 md:max-w-26'
                />
              </a>
            </Link>

            <div className='w-full mt-2 md:mt-3 font-normal text-xs md:text-lg text-white'>
              {translate('eventsSimplified')}
            </div>

            <div className='md:justify-start pt-10 pb-4 hidden md:block'>
              <ul className='list-none justify-start grid grid-flow-row grid-cols-5 grid-rows-2 gap-4 w-max'>
                {socialMediaIconList
                  .filter((item) => item.isVisible)
                  .map(({ url, faIcon, color }, index) => (
                    <SocialMediaIcon
                      key={index}
                      url={url}
                      faIcon={faIcon}
                      iconStyle={{ color }}
                      anchorStyle={{ borderColor: color }}
                      iconClass={'text-cinnabar'}
                      className={`p-2 h-7.5 w-7.5 rounded-full border text-white text-sm hover:text-about hover:bg-white md:hover:animate-spin box-border transition-all`}
                    />
                  ))}
              </ul>
            </div>
          </div>

          <div className='w-2/4 md:w-1/4 pt-4 md:pt-0'>
            <p className='text-xs font-bold md:text-lg font-medium uppercase text-white mb-3 md:mb-4'>
              {translate('company')}
            </p>
            <hr className='hr-line' />
            <ul className='pt:2 md:pt-3'>
              {companySectionData.map(
                ({ href, textKey, additionalClass }, index) => (
                  <CompanySection
                    key={index}
                    {...{ href, textKey, additionalClass }}
                  />
                )
              )}
            </ul>
          </div>

          <div className='w-2/4 md:w-1/4 pt-4 md:pt-0'>
            <p className='text-xs md:text-lg font-medium text-white uppercase mb-3'>
              {translate('explore')}
            </p>
            <hr className='hr-line' />
            <ul className='pt:2 md:pt-3'>
              {exploreSectionData.map(({ href, textKey }, index) => (
                <ExploreSection
                  key={index}
                  {...{ href, textKey }}
                />
              ))}
            </ul>
          </div>
          <div className='w-2/4 md:w-1/4 pt-4 md:pt-0'>
            <p className='text-xs md:text-lg font-medium text-white uppercase mb-3'>
              {translate('policy')}
            </p>
            <hr className='hr-line' />
            <ul className='pt:2 md:pt-3'>
              {policyData.map(({ href, textKey }, index) => (
                <ExploreSection
                  key={index}
                  {...{ href, textKey }}
                />
              ))}
            </ul>
          </div>

          <div className='w-full md:w-1/4'></div>
        </div>

        <div className='items-center justify-center md:justify-start pt-8 pb-1 space-x-4 md:hidden'>
          <ul className='list-none space-x-4 flex justify-center items-center'>
            {socialMediaIconList
              .filter((item) => item.isVisible)
              .map(({ url, faIcon, color }, index) => (
                <SocialMediaIcon
                  key={index}
                  url={url}
                  faIcon={faIcon}
                  iconStyle={{ color }}
                  anchorStyle={{ borderColor: color }}
                  iconClass={'text-cinnabar'}
                  className={`p-2 h-7.5 w-7.5 rounded-full border text-white text-sm hover:text-about hover:bg-white md:hover:animate-spin box-border transition-all`}
                />
              ))}
          </ul>
        </div>

        <div className='mt-4 md:mt-10'>
          <p className='text-xs md:text-base text-center md:text-left text-dim-gray'>
            {` ${translate('copyright')} `}
          </p>
        </div>
      </div>
    </footer>
  );
}
