import Image from 'next/image';
import { useState } from 'react';

import CustomSearchBox from 'components/Common/CustomSearchBoxDesktop';
import { staticMediaStoreBaseURL } from 'config/common';

export default function DesktopHomeBanner() {
  const [searchValue] = useState('');
  return (
    <div>
      <Image
        width={1850}
        height={718}
        layout='fixed'
        src={`${staticMediaStoreBaseURL}/home-page-banner/desktop-banner.jpg`}
        alt='Hafla desktop banner'
      />
      <div className='transform translate-y-4.5 max-w-1/3 items-center mx-auto w-full mb-5'>
        <CustomSearchBox
          searchedValue={searchValue}
          onScrolling={true}
          homeScreen={true}
          searchResultPage={true}
        />
      </div>
    </div>
  );
}
