import NextNProgress from 'nextjs-progressbar';

const ProgressBar = () => (
  <NextNProgress
    color='#ff794d'
    startPosition={0.1}
    stopDelayMs={100}
    height={8}
  />
);

export default ProgressBar;
